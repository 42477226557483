// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import background from '../assets/images/background.png';
import worldMapImage from '../assets/images/GradientMap.png';
import arrow1 from '../assets/images/Arrow1.png';
import arrow2 from '../assets/images/Arrow_2.png';
import arrow3 from '../assets/images/Arrow_3.png';
import headline1 from '../assets/images/headline1.jpg';
import headline2 from '../assets/images/headline2.jpg';
import headline3 from '../assets/images/headline3.jpg';
import headline4 from '../assets/images/headline4.jpg';
import headline5 from '../assets/images/headline5.jpg';
import headline6 from '../assets/images/headline6.jpg';
import headline7 from '../assets/images/headline7.jpg';
import headline8 from '../assets/images/headline8.jpg';
import phone from '../assets/images/Phone_Alert_Icon.png';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SurveyModal from '../components/SurveyModal';
import ExitIntentDetector from '../components/ExitIntentDetector';
import useExitSurveyModal from '../components/useExitSurveyModal';
import { isMobileDevice } from '../utils/deviceUtils';

const Homepage = () => {
      // Download sample PDF function
  const downloadSample = () => {
    const link = document.createElement('a');
    link.href = '/MapDoc7Demo.pdf';
    link.download = 'MapDoc7Demo.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    // Image slider state and effect for mobile hero
    const images = [headline1, headline2, headline3, headline4, headline5, headline6, headline7, headline8];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
      // Change image every 3 seconds
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);

      // Cleanup on unmount
      return () => clearInterval(interval);
    }, [images.length]);

    return (
      <div>
      <div className="min-h-screen bg-custom-200 text-white">
        {/* Desktop Version */}
        <div className="hidden md:block">
          <div className="container mx-auto py-10 px-4">
            {/* Top Images Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 md:mt-10 lg:grid-cols-4 lg:mt-5 gap-4 mb-10">
              <div className="py-10">
                <img src={headline1} alt="News Headline 1" className="w-full h-auto" />
              </div>
              <div className="py-10">
                <img src={headline2} alt="News Headline 2" className="w-full h-auto" />
              </div>
              <div className="hidden lg:block py-10">
                <img src={headline3} alt="News Headline 3" className="w-full h-auto" />
              </div>
              <div className="hidden lg:block py-10">
                <img src={headline4} alt="News Headline 4" className="w-full h-auto" />
              </div>
            </div>

            {/* Hero Section */}
            <div className="text-center mb-10">
              <h1 className="text-5xl font-bold mb-6">You Can't Afford to Wait.</h1>
              <div className="flex justify-center space-x-4">
                <Link to="/pricing">
                  <button 
                      onClick={() => {
                        ReactGA.event({
                          category: 'Pageview',
                          action: 'Homepage_Hero_pricingPage',
                          label: 'Pricing Page from Homepage Hero',
                        });
                      }}
                  className="bg-custom-100 text-white px-8 py-4 rounded-lg hover:bg-orange-700 transition">Explore Plans</button>
                </Link>
                <button
                  onClick={() => {
                    ReactGA.event({
                      category: 'Download',
                      action: 'Homepage_Hero_Download',
                      label: 'Sample PDF from Homepage Hero',
                    });
                    downloadSample();
                  }}
                  className="bg-transparent border-2 border-sky-600 text-white px-8 py-4 rounded-lg hover:bg-gray-700 transition"
                >
                  View Sample
                </button>
              </div>
            </div>

            {/* Bottom Images Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="hidden lg:block pt-10 pb-5">
                <img src={headline5} alt="News Headline 5" className="w-full h-auto" />
              </div>
              <div className="hidden lg:block pt-10 pb-5">
                <img src={headline6} alt="News Headline 6" className="w-full h-auto" />
              </div>
              <div className="pt-10 pb-5">
                <img src={headline7} alt="News Headline 7" className="w-full h-auto" />
              </div>
              <div className="pt-10 pb-5">
                <img src={headline8} alt="News Headline 8" className="w-full h-auto" />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Version */}
        <div className="md:hidden pt-12">
          <div className="text-center py-10">
            <img
              src={images[currentImageIndex]}
              alt={`Headline ${currentImageIndex + 1}`}
              className="w-full mb-6"
            />
            <h1 className="text-5xl font-bold mb-6">You Can't Afford to Wait.</h1>
            <div className="flex justify-center space-x-4">
              <Link to="/pricing">
                <button 
                  onClick={() => {
                    ReactGA.event({
                      category: 'Pageview',
                      action: 'Homepage_Hero_pricingPage',
                      label: 'Pricing Page from Homepage Hero',
                    });
                  }}
                  className="bg-custom-100 text-white px-8 py-4 rounded-lg hover:bg-orange-700 transition">Explore Plans</button>
              </Link>
              <button
                onClick={() => {
                  ReactGA.event({
                    category: 'Download',
                    action: 'Homepage_Hero_Download',
                    label: 'Sample PDF from Homepage Hero',
                  });
                  downloadSample();
                }}
                className="bg-transparent border-2 border-sky-600 text-white px-8 py-4 rounded-lg hover:bg-gray-700 transition"
              >
                View Sample
              </button>
            </div>
          </div>
        </div>

        {/* Shared Content */}
        <div className="relative w-full mx-auto px-4 py-20 text-white bg-gray-400 bg-opacity-20 mb-10">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="w-full md:w-1/2 mb-10 md:mb-0">
              <img src={worldMapImage} alt="World map" className="w-full h-auto" />
            </div>
            <div className="w-full md:w-1/2 px-4">
              <h2 className="text-4xl font-bold mb-6">Failing to plan is planning to fail.</h2>
              <p className="mb-4">
              Stay Safe Planning offers simple personalized guides that anyone can quickly understand. Our guides provide:
              </p>
              <ul className="list-disc list-outside ml-6 mb-4">
                <li>Personalized local evacuation maps </li>
                <li>Simple or comprehensive supply lists (based on plan tier) 
                <ul className="list-disc list-outside ml-14"><li className="italic">Many of these items are not stocked for high-demand, getting them early is critical </li></ul></li>
                <li>Moment by moment breakdowns of what to do immediately after an attack</li>
                <li>Step by step instructions on how to prepare and set up your shelter </li>
                <li>Detailed next steps following the critical 7, 49, and 300 hour time horizons</li>
              </ul>
              <p className="mb-4">
              We also offer our exclusive subscription add-on, Survival Edge, which turbo-charges your planning by offering in the moment personalized guidance throughout an entire emergency as well as quarterly test messages to make sure you, your family, and your supplies are ready before anything ever happens. Time is of the essence. Don’t waste it. You will not find guides like these anywhere else. You can survive, we can help.  </p>
            </div>
          </div>
        </div>

        <div className="relative container mx-auto px-4 py-20 text-white grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="text-center">
            <img src={arrow1} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
            <p>
              Pinpoint recommendations make sure you get exactly where you need to be when you need to be there.
            </p>
          </div>
          <div className="text-center">
            <img src={phone} alt="Phone Icon" className="mx-auto mb-4 w-12 h-12" />
            <p>
              Our first-of-its-kind Survival Edge subscription delivers immediate critical instructions so you don't waste a single precious moment. Check our{' '}
              <a href="/faq" className="text-blue-400 underline">
                FAQ
              </a>{' '}
              to learn more.
            </p>
          </div>
          <div className="text-center">
            <img src={arrow2} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
            <p>
              Get clear step-by-step guidance on how to plan, how to evaluate your options, and what you need to have with you.
            </p>
          </div>
          <div className="text-center">
            <img src={arrow3} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
            <p>
              You're joining an elite community by having a reliable and well-rehearsed plan in place to protect your family.
            </p>
          </div>
          {/* Vertical Lines */}
          <div className="absolute left-1/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
          <div className="absolute left-2/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
          <div className="absolute left-3/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
        </div>

        <div className="relative container mx-auto px-4 py-20 text-center text-white">
          <h2 className="text-4xl font-bold mb-6">There's so much more in our Guides.</h2>
          <p className="mb-8">
            Download a sample to get a sneak peek of exactly what you can expect to receive at purchase.
          </p>
          <button
            onClick={() => {
              ReactGA.event({
                category: 'Download',
                action: 'Homepage_Download',
                label: 'Sample PDF from Homepage',
              });
              downloadSample();
            }}
            className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};
  
  export default Homepage;
  
