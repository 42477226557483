// src/components/RedirectWithPageview.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RedirectWithPageview = ({ to, path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Send pageview for the initial URL
    ReactGA.send({ hitType: 'pageview', page: path });

    // Redirect to the target page
    navigate(to, { replace: true });
  }, [navigate, to, path]);

  return null;
};

export default RedirectWithPageview;
