// src/components/PricingSection.js
import React from 'react';
import PricingCard from './PricingCard';
import ReactGA from 'react-ga4';

const PricingSection = () => {
  const pricingData = [
    {
      title: 'Bare Minimum',
      price: '$39',
      perUnit: '/one-time',
      safetyLevel: 'High',
      features: [
        'Custom Local Map',
        '7-hour survival plan',
        'No or Low Cost Supply List',
        'No or Low Cost Techniques',
        'Survival Edge: $30 off w/ annual subscription at checkout'
      ],
      buttonText: 'Buy Now',
      annualPrice: '$29/1st year ($59 annually)',
      monthlyPrice: '$5.99/month',
      //Testing Price ID -  oneTimePriceId: 'price_1QEFz2EvKeJXTNSOE6irDgQo',
      oneTimePriceId: 'price_1QIIEAEvKeJXTNSOQOJVxnpC',
      special: false
    },
    {
      title: 'Enhanced Safety',
      price: '$79',
      perUnit: '/one-time',
      safetyLevel: 'Very High',
      features: [
        'Custom Local Map',
        '49-hour survival plan',
        'Cost-Optimized Supply List',
        'Expanded Survival Techniques',
        'Survival Edge: $40 off w/ annual subscription at checkout'
      ],
      buttonText: 'Buy Now',
      annualPrice: '$19/1st year ($59 annually)',
      monthlyPrice: '$5.99/month',
      //Testing Price ID -  oneTimePriceId: 'price_1QEG1kEvKeJXTNSO70L4uMuM',
      oneTimePriceId: 'price_1QIIE8EvKeJXTNSONASTjgY8',
      special: false
    },
    {
      title: 'Total Protection',
      price: '$129',
      perUnit: '/one-time',
      safetyLevel: 'Highest',
      features: [
        'Enhanced Custom Map',
        '14-day survival plan',
        'Ideal Supply List',
        'Best-Possible Techniques and Unique DIY Equipment',
        'Survival Edge: 1yr FREE'
      ],
      buttonText: 'Buy Now',
      annualPrice: '$59/year (1-year FREE)',
      monthlyPrice: '$5.99/month (1-year FREE)',
      //Testing Price ID -  oneTimePriceId: 'price_1QEGDvEvKeJXTNSOV7mDEcHf',
      oneTimePriceId: 'price_1QIIE5EvKeJXTNSODDV2MuiJ',
      special: false
    },
    {
        title: 'Survival Edge',
        price: '$59',
        perUnit: '/year or $5.99/month',
        features: [
          'Up to 20% more time to react',
          'Immediate Step-by-Step Plan',
          'Take action more quickly',
          '24-hour recurring updates',
          'Quarterly Rehearsal Notifications',
          'Custom Impact Maps/Data'
        ],
        buttonText: 'Subscribe Now',
        special: true
      }
  ];

  // Download sample PDF function
  const downloadSample = () => {
    const link = document.createElement('a');
    link.href = '/MapDoc7Demo.pdf';
    link.download = 'MapDoc7Demo.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <section className="bg-transparent py-10">
      <div className="container mx-auto px-4">
        <h2 className="mb-10 text-center text-4xl font-bold text-white">Our Pricing</h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 items-start">
          {pricingData.map((item, index) => (
            <PricingCard
              key={index}
              title={item.title}
              price={item.price}
              safetyLevel={item.safetyLevel}
              perUnit={item.perUnit}
              features={item.features}
              buttonText={item.buttonText}
              annualPrice={item.annualPrice}
              monthlyPrice={item.monthlyPrice}
              oneTimePriceId={item.oneTimePriceId}
              special={item.special}
            />
          ))}
        </div>
      </div>
      <div id="bottom" className="text-center mt-10">
  <h1 className="mb-3 max-w-2xl mx-auto text-white text-lg md:text-xl">
    Want to see a sample?
    <button
        onClick={() => {
            ReactGA.event({
            category: 'Download',
            action: 'Pricing_Download',
            label: 'Sample PDF from Pricing',
            });
            downloadSample(); // Your existing function to download the file
        }}
      className="ml-2 rounded bg-gray-700 px-4 py-2 text-white transition duration-200 hover:bg-gray-500"
    >
      Download Now
    </button>
  </h1>
</div>
    </section>
  );
};

export default PricingSection;
