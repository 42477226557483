// src/components/AttentionGrabber.js
import React from 'react';
import { MdOutlineDiscount } from "react-icons/md";

const AttentionGrabber = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed left-0 top-1/2 transform -translate-y-1/2 z-50
                     p-2 bg-orange-500 text-white rounded-r-full shadow-lg
                     animate-pulse focus:outline-none"
      aria-label="Open Survey"
    >
      {/* Icon or Text */}
      <MdOutlineDiscount className="h-6 w-6" />
    </button>
  );
};

export default AttentionGrabber;
