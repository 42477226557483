// src/pages/PricingPage.js
import React from 'react';
import HeroSection from '../components/HeroSection';
import PricingSection from '../components/PricingSection';
import background from '../assets/images/background5.png';

const PricingPage = () => {


      const pricingPageQuestion = 'What do you think of our plan options?';

        const pricingPageOptions = [
        { value: "I don't think the risk is high enough to buy a plan", label: "I don't think the risk is high enough to buy a plan" },
        { value: "Plans look interesting, I might buy later.", label: "Plans look interesting, I might buy later." },
        { value: 'The pricing is too high.', label: 'The pricing is too high.' },
        { value: 'I need more information about the plans.', label: 'I need more information about the plans.' },
        { value: "I'm not ready to make a purchase.", label: "I'm not ready to make a purchase."},
        { value: 'other', label: 'Other:' },
        ];

  return (
    <div 
      className="min-h-screen bg-cover bg-center" 
      style={{
        backgroundImage: `url(${background})`,
      }}
    >

      {/* Overlay for slight transparency */}
      <div className="min-h-screen bg-gray-500 bg-opacity-80">
        
        {/* Hero Section */}
        <HeroSection />
        
        {/* Pricing Section */}
        <PricingSection />

      </div>
    </div>
  );
};

export default PricingPage;
