import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css'; // Import Tailwind CSS here
import StripeProvider from './StripeProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StripeProvider>
        <App />
      </StripeProvider>
    </BrowserRouter>
  </React.StrictMode>,
    document.getElementById('root')
);
