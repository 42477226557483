// src/App.js
import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import RedirectWithPageview from './components/RedirectWithPageview';
import { initGA, logPageView } from './config/analytics';
import ReactGA from 'react-ga4';
import useExitSurveyModal from './components/useExitSurveyModal';
import SurveyModal from './components/SurveyModal';
import AttentionGrabber from './components/AttentionGrabber';
import ExitIntentDetector from './components/ExitIntentDetector';
import { isMobileDevice } from './utils/deviceUtils';

// Importing components and pages
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import FAQPage from './pages/FAQPage';
import LoginPage from './pages/LoginPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import Success from './pages/Success';
import WhyUsPage from './pages/WhyUsPage';

function App() {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []); // Run only on mount

  useEffect(() => {
    logPageView();
  }, [location]); // Track page view on route change

  // Scroll event tracking
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      if (scrollPercentage > 25 && scrollPercentage <= 50) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_25',
          label: location.pathname,
        });
      } else if (scrollPercentage > 50 && scrollPercentage <= 75) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_50',
          label: location.pathname,
        });
      } else if (scrollPercentage > 75 && scrollPercentage <= 100) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_75',
          label: location.pathname,
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  // Outbound link tracking
  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest('a');
      if (target && target.href && !target.href.includes(window.location.hostname)) {
        ReactGA.event({
          category: 'Outbound',
          action: 'Clicked Outbound Link',
          label: target.href,
        });
      }
    };

    document.addEventListener('click', handleLinkClick);

    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []);

  const {
    isModalOpen,
    setIsModalOpen,
    modalHeadline,
    handleExitIntent,
    hasModalBeenShown,
    showAttentionGrabber,
    handleAttentionGrabberClick,
    handleModalClose,
  } = useExitSurveyModal({ dwellTime: 15000 });

  useEffect(() => {
    // Initialize timeOnSite from localStorage
    let timeOnSite = parseInt(localStorage.getItem('timeOnSite'), 10);
    if (isNaN(timeOnSite)) {
      timeOnSite = 0;
    }

    const startTime = Date.now() - timeOnSite;

    const interval = setInterval(() => {
      const elapsed = Date.now() - startTime;
      localStorage.setItem('timeOnSite', elapsed);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Define your survey question and options here
  // Define survey question and options based on current path
  let surveyQuestion = 'Is there anything we could do to improve your experience?';
  let surveyOptions = [
    { value: "I'm just curious/not ready to buy.", label: "I'm just curious/not ready to buy." },
    { value: "Things look interesting, I might buy later.", label: "Things look interesting, I might buy later." },
    { value: "I didn't find what I was looking for.", label: "I didn't find what I was looking for." },
    { value: "Not what I expected.", label: "Not what I expected." },
    { value: 'Too much to read.', label: 'Too much to read.' },
    { value: 'Too expensive.', label: 'Too expensive.' },
    { value: 'other', label: 'Other:' },
  ];

  if (location.pathname === '/pricing') {
    surveyQuestion = 'What do you think of our plan options?';
    surveyOptions = [
      { value: "I don't think the risk is high enough to buy a plan", label: "I don't think the risk is high enough to buy a plan" },
      { value: "Plans look interesting, I might buy later.", label: "Plans look interesting, I might buy later." },
      { value: 'The pricing is too high.', label: 'The pricing is too high.' },
      { value: 'I need more information about the plans.', label: 'I need more information about the plans.' },
      { value: "I'm not ready to make a purchase.", label: "I'm not ready to make a purchase." },
      { value: 'other', label: 'Other:' },
    ];
  }

  const handleSurveySubmit = async ({ response, email }) => {
    // Generate a unique responseId
    const responseId = `response-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

    // Include the page path
    const data = {
      responseId,
      response,
      timestamp: new Date().toISOString(),
      page: window.location.pathname,
      email: email || null,
    };

    // Send data to your backend API
    try {
      await fetch('/api/submit-exit-survey', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Error submitting survey response:', error);
    }

    // Close the modal
    handleModalClose();
  };

  return (

    <>
      <ScrollToTop /> {/* Scrolls to the top on each route change */}
      <Navbar />

          {/* Exit Intent Detector for Desktop */}
          {!isMobileDevice() && !hasModalBeenShown && (
            <ExitIntentDetector onExitIntent={handleExitIntent} />
          )}

          {/* Survey Modal */}
          <SurveyModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onSubmit={handleSurveySubmit}
            headline={modalHeadline}
            question={surveyQuestion}
            options={surveyOptions}
          />

          {/* Attention Grabber for Mobile */}
          {isMobileDevice() && showAttentionGrabber && !hasModalBeenShown && (
            <AttentionGrabber onClick={handleAttentionGrabberClick} />
          )}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/order-details" element={<OrderDetailsPage />} />
          <Route path="/why-us" element={<WhyUsPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/baremin" element={<RedirectWithPageview to="/pricing" path="/baremin" />} />
          <Route path="/ga" element={<RedirectWithPageview to="/" path="/ga" />} />
          <Route path="/totalprot" element={<RedirectWithPageview to="/pricing" path="/totalprot" />} />
          <Route path="/enhancedsaf" element={<RedirectWithPageview to="/pricing" path="/enhancedsaf" />} />
          <Route path="/se" element={<RedirectWithPageview to="/pricing" path="/se" />} />
          <Route path="/pricingga" element={<RedirectWithPageview to="/pricing" path="/pricingga" />} />
          <Route path="/c" element={<RedirectWithPageview to="/" path="/c" />} />
        </Routes>
        </>
  );
}

export default App;
