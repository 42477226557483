// src/components/useExitSurveyModal.js
import { useState, useEffect } from 'react';
import { isMobileDevice } from '../utils/deviceUtils';

const useExitSurveyModal = (options = {}) => {
  const {
    dwellTime = 15000, // Default to 15,000 ms (15 seconds)
  } = options;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeadline, setModalHeadline] = useState('');
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
  const [showAttentionGrabber, setShowAttentionGrabber] = useState(false);

  useEffect(() => {
    // Initialize hasModalBeenShown from localStorage
    const hasSeenModal = localStorage.getItem('hasSeenExitModal') === 'true';
    setHasModalBeenShown(hasSeenModal);

    if (!hasSeenModal && isMobileDevice()) {
      const checkTimeOnSite = () => {
        const timeOnSite = parseInt(localStorage.getItem('timeOnSite'), 10);
        if (!isNaN(timeOnSite) && timeOnSite >= dwellTime) {
          setShowAttentionGrabber(true);
        }
      };

      // Check immediately
      checkTimeOnSite();

      // Set up interval to check every second
      const interval = setInterval(() => {
        checkTimeOnSite();
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dwellTime]);

  const handleExitIntent = () => {
    if (!hasModalBeenShown) {
      setModalHeadline('Quick question before you go');
      setIsModalOpen(true);
      setHasModalBeenShown(true);
      localStorage.setItem('hasSeenExitModal', 'true');
      localStorage.removeItem('timeOnSite');
    }
  };

  const handleAttentionGrabberClick = () => {
    setModalHeadline('Sorry to interrupt, could you share some quick feedback? (all responses are anonymous)');
    setIsModalOpen(true);
    setShowAttentionGrabber(false);
    setHasModalBeenShown(true);
    localStorage.setItem('hasSeenExitModal', 'true');
    localStorage.removeItem('timeOnSite');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setHasModalBeenShown(true);
    setShowAttentionGrabber(false);
    localStorage.setItem('hasSeenExitModal', 'true');
    localStorage.removeItem('timeOnSite');
  };

  return {
    isModalOpen,
    setIsModalOpen,
    modalHeadline,
    handleExitIntent,
    hasModalBeenShown,
    showAttentionGrabber,
    handleAttentionGrabberClick,
    handleModalClose,
  };
};

export default useExitSurveyModal;
