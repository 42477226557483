// src/pages/LoginPage.js
import React from 'react';
import background from '../assets/images/background2.png';
import worldMapImage from '../assets/images/GradientMap.png';
import { Link, useLocation } from 'react-router-dom';

const LoginPage = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center relative flex items-center justify-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute inset-0 bg-gray-500 bg-opacity-80"></div>
      <div className="relative flex items-center justify-between w-full max-w-5xl p-8 bg-opacity-60 bg-gray-800 rounded-3xl shadow-lg md:mt-20 md:justify-between md:max-w-5xl">
        <div className="text-white w-full md:w-1/2">
          <h1 className="text-4xl font-bold mb-4">Welcome back.</h1>
          <p className="mb-6">Please login to your account.</p>
          <form className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
              <input
                type="email"
                id="email"
                className="text-black w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="SampleEmail103@gmail.com"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium mb-1">Password</label>
              <input
                type="password"
                id="password"
                className="text-black w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="************"
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input type="checkbox" id="remember-me" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                <label htmlFor="remember-me" className="ml-2 block text-sm">Remember Me</label>
              </div>
              <a href="#" className="hidden text-sm text-blue-500 hover:underline">Forgot Password?</a>
            </div>
          </form>
          <div className="mt-6 flex space-x-4">
            <button className="bg-custom-100 text-white px-6 py-3 rounded-lg hover:bg-orange-700 transition">
              Login
            </button>
            <Link to="/pricing">
            <button className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
              Sign Up
            </button>
         </Link>
          </div>
        </div>
        <div className="ml-8 w-1/2 hidden md:block">
          <img src={worldMapImage} alt="World map" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

